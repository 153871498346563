<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id!=null">Edit</b>
      <b v-else>Create</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Code"
          v-validate="'required'"
          name="Code"
          v-model="data.code"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('Code')"
        >{{ errors.first('Code') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{ errors.first('Name') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <!-- <vs-select class="w-full" filter v-validate="'required'" label="Risk Cotegory" name="risk_category_id" v-model="data.risk_category_id">
          <vs-select-item
            v-for="(item,index) in risk_categories"
            :key="index"
            :value="item.ID"
            :text="item.code" />
        </vs-select>
        <span
          class="text-danger text-sm"
          v-show="errors.has('risk_category_id')"
        >{{ errors.first('risk_category_id') }}</span> -->
        <div class="ml-2">Risk Category</div>
        <v-select :options="risk_categories" v-model="risk_category" label="code">
          <!-- <template #option="{ code }"
            >{{ code }}</template
          >
          <template #selected-option="{ code }">
            <div style="display: flex; align-items: baseline">
              <strong>{{ code }}</strong>
            </div>
          </template> -->
        </v-select>
        <span
          class="text-danger text-sm"
          v-show="errors.has('risk_category_id')"
        >{{ errors.first('risk_category_id') }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select"
export default {
  components: {
    "v-select": vSelect
  },
  props: {
    id: {
      type: Number
    },
    url: {
      type: String
    }
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          name: "",
          code: "",
          risk_category_id: "",
        },
        risk_categories: [],
        risk_category: {},
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      return {
        name: this.data.name,
        code: this.data.code,
        risk_category_id: this.risk_category.ID,
        level: 1,
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.ID;
            this.data.name = resp.data.name;
            this.data.code = resp.data.code;
            // this.data.risk_category_id = resp.data.risk_category_id || null;
            this.risk_category = this.risk_categories.find(d => d.ID === resp.data.risk_category_id) || {};
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getRiskCategory() {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer-risk-category").then(res => {
        this.$vs.loading.close();
        if (res.code == 200) {
          this.risk_categories = res.data
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: res.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
      })
      .catch(error => {
        this.$vs.loading.close();
        console.log(error);
      });
    }
  },
  mounted() {
    this.getRiskCategory();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    }
  }
};
</script>
<style >
</style>
