<template>
  <div class="vx-col w-11/12">
    <!-- <perfect-scrollbar> -->
    <div class="vx-col w-11/12 ml-auto mr-auto">
      <span>
        <b v-if="customerId != null">Edit Customer</b>
        <b v-else>Create Customer</b>
      </span>
      <div class="vx-row mb-6">
        <div class="vx-col w-full" v-if="customerId != null">
          <vs-input disabled class="w-full" label="Code" v-model="data.code" />
        </div>
        <div class="vx-col w-full" v-else>
          <vs-input class="w-full" label="Code" v-model="data.code" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="External Code" v-model="data.codeExternal" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Name"
            v-validate="'required'"
            name="Name"
            v-model="data.name"
          />
          <span class="text-danger text-sm" v-show="errors.has('Name')">{{
            errors.first("Name")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Nik" v-model="data.nik" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Status</label>
          <Status :status="data.status" @data="setStatus"></Status>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Bussiness Partner</label>
          <bussiness-partner
            :id="data.parentId"
            @parentId="setParentId"
          ></bussiness-partner>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Distribution Channel</label>
          <div class="vx-row">
            <div class="vx-col w-5/6">
              <multiselect
                v-model="customerCategory.selected"
                :options="customerCategory.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToCustomerCategory"
              ></vs-button>
            </div>
          </div>

          <span
            class="text-danger text-sm"
            v-show="errors.has('CustomerCategory')"
            >{{ errors.first("CustomerCategory") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 1</label>
          <div class="vx-row">
            <div class="vx-col w-5/6">
              <multiselect
                v-model="customerGroup1.selected"
                :options="customerGroup1.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToCustomerGroup"
              ></vs-button>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('CustomerGroup1')"
            >{{ errors.first("CustomerGroup1") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 2</label>
          <div class="vx-row">
            <div class="vx-col w-5/6">
              <multiselect
                v-model="customerGroup2.selected"
                :options="customerGroup2.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToCustomerGroup"
              ></vs-button>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 3</label>
          <div class="vx-row">
            <div class="vx-col w-5/6">
              <multiselect
                v-model="customerGroup3.selected"
                :options="customerGroup3.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToCustomerGroup"
              ></vs-button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Payment Method</label>
          <payment-method
            :id="data.paymentMethodId"
            @data="setPaymentMethodId"
          ></payment-method>
          <span
            class="text-danger text-sm"
            v-show="errors.has('PaymentMethod')"
            >{{ errors.first("PaymentMethod") }}</span
          >
        </div>
      </div> -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Payment Type</label>
          <multiselect
            v-model="paymentType.selected"
            :options="paymentType.options"
            placeholder="Type to search"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Payment Term</label>
          <div class="vx-row">
            <div class="vx-col w-5/6">
              <multiselect
                v-model="paymentTerm.selected"
                :options="paymentTerm.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToPaymentTerm"
              ></vs-button>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('PaymentTerm')"
            >{{ errors.first("PaymentTerm") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-5/6">
          <label class="vs-input--label">Pricing Group</label>
          <multiselect
            v-model="pricingGroup.selected"
            :options="pricingGroup.options"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.name }} ({{ props.option.code }})</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.name }} ({{ props.option.code }})</span
                >
              </div>
            </template>
          </multiselect>
        </div>
        <div class="vx-col w-1/6">
            <vs-button
              color="dark"
              type="line"
              icon-pack="feather"
              icon="icon-plus-square"
              @click="pushToPricingGroup"
            ></vs-button>
          </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Delivery Type</label>
          <multiselect
            v-model="deliveryType.selected"
            :options="deliveryType.options"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <!--
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Territory</label>
          <multiselect
            v-model="territory.selected"
            :options="territory.options"
            :multiple="true"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Zone</label>
          <multiselect
            v-model="zone.selected"
            :options="zone.options"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Sales</label>
          <multiselect
            v-model="sales.selected"
            :options="sales.options"
            placeholder="Type to search"
            track-by="Name"
            label="Name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.Name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.Name }}</span>
              </div>
            </template>
          </multiselect>
          <span
            class="text-danger text-sm"
            v-show="errors.has('SalesCustomer')"
            >{{ errors.first("SalesCustomer") }}</span
          >
        </div>
      </div>
      -->
      <!-- <span class="text-danger text-sm" v-show="errors.has('CountryName')">{{
        errors.first("CountryName")
      }}</span> -->
      <address-component
        :data="data.address"
        @data="setDataAddress"
      ></address-component>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Contact Name"
            v-model="data.contactName"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Phone"
            v-model="data.phone"
            v-validate="'required|numeric'"
            name="Phone"
          />
          <span class="text-danger text-sm" v-show="errors.has('Phone')">{{
            errors.first("Phone")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Mobile"
            v-model="data.mobile"
            v-validate="'numeric'"
            name="Mobile"
          />
          <span class="text-danger text-sm" v-show="errors.has('Mobile')">{{
            errors.first("Mobile")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Email"
            v-model="data.email"
            v-validate="'email'"
            name="Email"
          />
          <span class="text-danger text-sm" v-show="errors.has('Email')">{{
            errors.first("Email")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            v-if="customerId != null"
            class="w-full"
            label="Password"
            v-model="data.password"
            name="password"
            type="password"
          />
          <vs-input
            v-else
            class="w-full"
            label="Password"
            v-validate="'required'"
            v-model="data.password"
            name="password"
            type="password"
          />
          <span class="text-danger text-sm" v-show="errors.has('Password')">{{
            errors.first("Password")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Fax"
            v-model="data.fax"
            v-validate="'numeric'"
            name="Fax"
          />
          <span class="text-danger text-sm" v-show="errors.has('Fax')">{{
            errors.first("Fax")
          }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Photo</span>
          </div>
          <div class="vx-col w-full">
            <input
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              accept=".jpg, .jpeg, .png"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span></span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <img style="max-height: 170px; max-width: auto;" :src="data.photo" />
          </div>
        </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Credit Limit"
            v-model="data.customerLimit.amount"
            name="CreditLimit"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('CreditLimit')"
            >{{ errors.first("CreditLimit") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Priority</label>
          <multiselect
            v-model="priority.selected"
            :options="priority.options"
            placeholder="Type to search"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Invoice Model</label>
          <br />
          <ul class="centerx">
            <li>
              <vs-radio v-model="data.invoiceModel" vs-value="When Shipping"
                >When Shipping</vs-radio
              >&nbsp;&nbsp;&nbsp;
            </li>
            <li>
              <vs-radio v-model="data.invoiceModel" vs-value="Separated"
                >Separated</vs-radio
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Tax Type</label>
          <div class="vx-row">
            <div class="vx-col w-5/6">
              <multiselect
                v-model="taxType.selected"
                :options="taxType.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Tax Collector</label>
          <vs-switch
            color="dark"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.isTaxCollector"
          >
            <span slot="on">YES</span>
            <span slot="off">NO</span>
          </vs-switch>
        </div>
      </div> -->
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Tax Exempt</label>
          <vs-switch
            color="dark"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.isTaxExempt"
          >
            <span slot="on">YES</span>
            <span slot="off">NO</span>
          </vs-switch>
        </div>
      </div> -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">PKP</label>
          <vs-switch
            color="dark"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.isPkp"
          >
            <span slot="on">YES</span>
            <span slot="off">NO</span>
          </vs-switch>
        </div>
      </div>
      <template>
        <div v-if="data.isPkp == true">
          <div class="vx-row mb-3">
            <div class="vx-col w-full ml-6">
              <label class="vs-input--label">NPWP Type</label>
              <multiselect
                v-model="npwpType.selected"
                :options="npwpType.options"
                placeholder="Type to search"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{ props.option }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option }}</span>
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="NPWP"
                v-model="data.npwp"
                name="NPWP"
              />
            </div>
          </div>
          <!-- <div class="vx-row mb-3">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="TDP"
                v-model="data.tdp"
                name="TDP"
              />
            </div>
          </div> -->
          <div class="vx-row mb-3">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="NPWP Name"
                v-model="data.npwpName"
              />
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="SPPKP"
                v-model="data.sppkp"
                name="SPPKP"
              />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="Tax Address"
                v-model="data.taxAddress"
                name="taxAddress"
              />
            </div>
          </div>
          <!-- <div class="vx-row mb-6">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="Domisili"
                v-model="data.domisili"
                name="domisili"
              />
            </div>
          </div> -->
        </div>
      </template>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
    <!-- </perfect-scrollbar> -->
  </div>
</template>
<script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import PaymentMethod from "./form-component/PaymentMethod";
import BussinessPartner from "./form-component/BussinessPartner";
import Status from "./form-component/Status";
import PriorityType from "./form-component/PriorityType";
import AddressComponent from "@/components/master/Address";

export default {
  components: {
    PerfectScrollbar,
    PaymentMethod,
    BussinessPartner,
    Status,
    PriorityType,
    AddressComponent,
  },
  props: {
    customerId: {
      type: Number,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/customer",
        data: {
          parentID: null,
          customerGroupId: null,
          customerCategoryId: null,
          paymentTermId: null,
          paymentMethodId: null,
          taxTypeId: null,
          pricingGroupId: null,
          deliveryTypeId: null,
          customerGroupId1: null,
          customerGroupId2: null,
          customerGroupId3: null,
          code: "",
          codeExternal: "",
          name: "",
          contactName: "",
          phone: "",
          mobile: "",
          email: "",
          password: "",
          fax: "",
          isPkp: true,
          // isTaxCollector: true,
          // isTaxExempt: true,
          npwp: "",
          tdp: "",
          siup: "",
          npwpName: "",
          sppkp: "",
          //domisili: "",
          taxAddress: "",
          nik: "",
          photo: "",
          invoiceModel: "When Shipping",
          priorityType: "Platinum",
          customerLimit: {
            amount: 0,
          },
          customerZone: {
            zoneId: null,
          },
          address: {
            address: "",
            postalCode: "",
            countryName: "",
            countryID: 0,
            provinceName: "",
            provinceID: 0,
            cityName: "",
            cityID: 0,
            transportZone: "",
            districtName: "",
            districtID: 0,
            subdistrictName: "",
            subdistrictID: 0,
            showWarningAddress: false,
          },
        },
        zone: {
          selected: null,
          options: [],
        },
        city: {
          id: null,
          provinceId: null,
          name: "",
        },
        customerCategory: {
          selected: null,
          options: [],
        },
        customerGroup1: {
          selected: null,
          options: [],
        },
        customerGroup2: {
          selected: null,
          options: [],
        },
        customerGroup3: {
          selected: null,
          options: [],
        },
        paymentTerm: {
          selected: null,
          options: [],
        },
        taxType: {
          selected: null,
          options: [],
        },
        pricingGroup: {
          selected: null,
          options: [],
        },
        deliveryType: {
          selected: null,
          options: [],
        },
        territory: {
          selected: null,
          options: [],
        },
        sales: {
          selected: null,
          options: [],
        },
        priority: {
          selected: "Platinum",
          options: ["Platinum","Gold","Silver","Bronze"],
        },
        npwpType: {
          selected: "Personal",
          options: ["Personal","Company"],
        },
        paymentType: {
          selected: "Cash",
          options: ["Cash","Credit"],
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!this.data.customerCategoryId) {
          this.errors.add({
            field: "CustomerCategory",
            msg: "The Distribution Channel field is required",
          });
          result = false;
        }
        if (!this.data.customerGroupId1) {
          this.errors.add({
            field: "CustomerGroup1",
            msg: "The Customer Group 1 field is required",
          });
          result = false;
        }
        // if (!this.data.paymentMethodId) {
        //   this.errors.add({
        //     field: "PaymentMethod",
        //     msg: "The Payment Method field is required",
        //   });
        //   result = false;
        // }
        if (!this.data.paymentTermId) {
          this.errors.add({
            field: "PaymentTerm",
            msg: "The Payment Term field is required",
          });
          result = false;
        }
        // if (!this.sales.selected) {
        //   this.errors.add({
        //     field: "SalesCustomer",
        //     msg: "The Sales field is required",
        //   });
        //   result = false;
        // }
        if (!this.data.email) {
          this.errors.add({
            field: "Email",
            msg: "The Email field is required",
          });
          result = false;
        }
        if (!this.data.password && this.customerId == null) {
          this.errors.add({
            field: "Password",
            msg: "The Password field is required",
          });
          result = false;
        }
        if (this.data.password.length < 8 && this.customerId == null) {
          this.errors.add({
            field: "Password",
            msg: "The Password at least 8 character",
          });
          result = false;
        }

        let amount = 0;
        if (
          this.data.customerLimit.amount != "" ||
          this.data.customerLimit.amount != null
        ) {
          amount = parseInt(
            this.data.customerLimit.amount.toString().replace(/\./g, "")
          );
        }

        if (amount < 1) {
          this.errors.add({
            field: "CreditLimit",
            msg: "The CreditLimit field is must greater than 0",
          });
          result = false;
        }

        if (result) {

          var file = this.$refs.file.files[0];
          if (file != null) {
            var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
            if (!validImageTypes.includes(file.type)) {
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: "File must be an image",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            } else {
              var name =
                this.data.code +
                "." +
                file.name.split(".").pop();
              this.data.photo = name;
              this.formData = new FormData();
              this.formData.append("file", file);
              this.formData.append("name", name);

              this.$vs.loading();
              this.$http
                .post("api/v1/master/customer/photo", this.formData, {
                  headers: {
                    "Content-Type": "multipart/form-data"
                  }
                })
                .then(resp => {
                  this.$vs.loading.close();
                  if (resp.status == "success") {
                    if (this.customerId) {
                      this.putData();
                    } else {
                      this.postData();
                    }
                  } else {
                    this.$vs.notify({
                      color: "danger",
                      title: "Error",
                      text: resp.message,
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle"
                    });
                  }
                });
            }
          }else{

            if (this.customerId) {
              this.putData();
            } else {
              this.postData();
            }

          }

        }
      });
    },
    paramData() {
      let is_pkp = "0";
      if (this.data.isPkp) {
        is_pkp = "1";
      } else {
        this.data.npwp = "-";
        this.data.tdp = "-";
        this.data.siup = "-";
        this.data.sppkp = "-";
        //this.data.domisili = "-";
        this.data.taxAddress = "-";
        this.npwpType.selected = "Non PKP";
      }

      // let is_tax_collector = "0";
      // if (this.data.isTaxCollector) {
      //   is_tax_collector = "1";
      // }
      // let is_tax_exempt = "0";
      // if (this.data.isTaxExempt) {
      //   is_tax_exempt = "1";
      // }
      // var territoryIds = [];
      // if (Array.isArray(this.territory.selected)) {
      //   this.territory.selected.forEach(function (item) {
      //     territoryIds.push(item.id ? item.id : item.ID);
      //   }, territoryIds);
      // } else {
      //   territoryIds.push(this.territory.selected.id ? this.territory.selected.id : this.territory.selected.ID);
      // }

      let amount = 0;
      if (this.data.customerLimit.amount != "") {
        amount = parseInt(
          this.data.customerLimit.amount.toString().replace(/\./g, "")
        );
      }
      return {
        parent_id: this.data.parentId,
        customer_group_id1: this.data.customerGroupId1,
        customer_group_id2: this.data.customerGroupId2,
        customer_group_id3: this.data.customerGroupId3,
        payment_term_id: this.data.paymentTermId,
        pricing_group_id: this.data.pricingGroupId,
        payment_method_id: this.data.paymentMethodId,
        customer_category_id: this.data.customerCategoryId,
        delivery_type_id: this.data.deliveryTypeId,
        //territory_ids: territoryIds,
        code: this.data.code,
        code_external: this.data.codeExternal,
        name: this.data.name,
        // address: this.data.address,
        //zone_id: this.data.customerZone.zoneId,
        //sales_personal_id: this.sales.selected.ID,
        // city: this.data.city,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        sub_district: this.data.address.subdistrictName,
        address: this.data.address.address,
        postal_code: this.data.address.postalCode,
        contact_name: this.data.contactName,
        mobile: this.data.mobile,
        phone: this.data.phone,
        email: this.data.email,
        password: this.data.password,
        fax: this.data.fax === "" ? "" : this.data.fax,
        // is_tax_collector: is_tax_collector,
        // is_tax_exempt: is_tax_exempt,
        is_pkp: is_pkp,
        npwp: this.data.npwp === "" ? "-" : this.data.npwp,
        tdp: this.data.tdp === "" ? "-" : this.data.tdp,
        siup: this.data.siup === "" ? "-" : this.data.siup,
        npwp_name: this.data.npwpName,
        sppkp: this.data.sppkp === "" ? "-" : this.data.sppkp,
        //domisili: this.data.domisili === "" ? "-" : this.data.domisili,
        tax_address: this.data.taxAddress === "" ? "-" : this.data.taxAddress,
        invoice_model: this.data.invoiceModel,
        status: this.data.status,
        type: "End Customer",
        priority_type: this.data.priorityType,
        amount: amount,
        nik: this.data.nik,
        photo: this.data.photo,
        npwp_type: this.npwpType.selected,
        payment_type: this.paymentType.selected,
        tax_type_id: this.data.taxTypeId,
      };
    },
    postData() {
      this.$vs.loading();
      this.$http
        .post(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Customer Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$vs.loading();
      this.$http
        .put(this.baseUrl + "/" + this.customerId, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Customer Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.customerId) {
        console.log("get data")
        this.$http
          .get(this.baseUrl + "/" + this.customerId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.data.parentId = resp.data.parent_id;
              this.data.code = resp.data.code;
              this.data.codeExternal = resp.data.code_external;
              this.data.name = resp.data.name;
              // this.data.address = resp.data.address;
              this.data.contactName = resp.data.contact_name;
              this.data.phone = resp.data.phone;
              this.data.mobile = resp.data.mobile;
              this.data.email = resp.data.email;
              this.data.fax = resp.data.fax;
              this.data.paymentMethodId = resp.data.payment_method_id;
              this.data.nik = resp.data.nik;
              this.data.photo = resp.data.photo+"?"+new Date().getTime();
              if (resp.data.invoice_model) {
                this.data.invoiceModel = resp.data.invoice_model;
              }
              if (resp.data.priority_type) {
                this.priority.selected = resp.data.priority_type;
              }
              if (resp.data.npwp_type) {
                this.npwpType.selected = resp.data.npwp_type;
              }
              if (resp.data.payment_type) {
                this.paymentType.selected = resp.data.payment_type;
              }
              if (resp.data.status) {
                this.data.status = resp.data.status;
              }
              // this.city.name = resp.data.city;
              this.data.address.address = resp.data.address;
              this.data.address.postalCode = resp.data.postal_code;
              this.data.address.countryName = resp.data.country;
              this.data.address.provinceName = resp.data.province;
              this.data.address.cityName = resp.data.city;
              this.data.address.districtName = resp.data.district;
              this.data.address.subdistrictName = resp.data.sub_district;

              this.data.address.countryID = resp.data.country_id;
              this.data.address.provinceID = resp.data.province_id;
              this.data.address.cityID = resp.data.city_id;
              this.data.address.districtID = resp.data.district_id;
              this.data.address.subdistrictID = resp.data.sub_district_id;
              // if (resp.data.is_tax_collector == "1") {
              //   this.data.isTaxCollector = true;
              // } else {
              //   this.data.isTaxCollector = false;
              // }
              // if (resp.data.is_tax_exempt == "1") {
              //   this.data.isTaxExempt = true;
              // } else {
              //   this.data.isTaxExempt = false;
              // }
              if (resp.data.is_pkp == "1") {
                this.data.isPkp = true;
              } else {
                this.data.isPkp = false;
              }
              this.data.npwp = resp.data.npwp;
              this.data.tdp = resp.data.tdp;
              this.data.siup = resp.data.siup;
              this.data.npwpName = resp.data.npwp_name;
              this.data.sppkp = resp.data.sppkp;
              //this.data.domisili = resp.data.domisili;
              this.data.taxAddress = resp.data.tax_address;
              this.data.customerLimit.amount = resp.data.customer_limit.amount;
              //this.data.customerZone.zoneId = resp.data.customer_zone.zone_id;

              if (resp.data.customer_category_id) {
                this.setCustomerCategorySelected(
                  resp.data.customer_category_id
                );
              }

              if (resp.data.pricing_group_id) {
                this.setPricingGroupSelected(
                  resp.data.pricing_group_id
                );
              }

              this.customerGroup1.selected = null;
              this.customerGroup2.selected = null;
              this.customerGroup3.selected = null;

              if (resp.data.customer_group_id1) {
                this.setCustomerGroup1Selected(resp.data.customer_group_id1);
              }

              if (resp.data.customer_group_id2) {
                this.setCustomerGroup2Selected(resp.data.customer_group_id2);
              }

              if (resp.data.customer_group_id3) {
                this.setCustomerGroup3Selected(resp.data.customer_group_id3);
              }

              if (resp.data.payment_term_id) {
                this.setPaymentTermSelected(resp.data.payment_term_id);
              }

              if (resp.data.delivery_type_id) {
                this.setDeliveryTypeSelected(resp.data.delivery_type_id);
              }
              console.log("resp.data", resp.data)

              if (resp.data.tax_type_id) {
                this.setTaxTypeSelected(resp.data.tax_type_id);
              }

              // if (resp.data.customer_zone.zone_id) {
              //   this.sales.selected = null;
              //   this.setZoneSelected(resp.data.customer_zone.zone_id);
              //   this.getDataSales(resp.data.customer_zone.zone_id);
              // } else {
              //   this.zone.selected = [];
              //   this.sales.selected = null;
              // }

              // this.territory.selected = [];
              // var getDataTerritory = this.getDataTerritory;
              // resp.data.customer_territory.forEach(function (item) {
              //   getDataTerritory(item.territory_id);
              // }, getDataTerritory);

              // if (resp.data.sales_personal_id) {
              //   this.setSalesPersonalSelected(resp.data.sales_personal_id);
              // }
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    getAllDataCustomerCategory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-category", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerCategory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataPricingGroup() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/pricing-group", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.pricingGroup.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataCustomerGroup1() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-group", {
          params: {
            order: "name",
            sort: "asc",
            level: 1,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup1.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataCustomerGroup2() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-group", {
          params: {
            order: "name",
            sort: "asc",
            level: 2,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup2.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    getAllDataCustomerGroup3() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-group", {
          params: {
            order: "name",
            sort: "asc",
            level: 3,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup3.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataPaymentTerm() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/payment-term", {
          params: {
            order: "name",
            sort: "asc",
            //payment_method_id: this.data.paymentMethodId,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.paymentTerm.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.zone.selected = {};
            this.territory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataTerritory(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/territory/" + id).then((resp) => {
        if (resp.status == "success") {
          this.territory.selected.push(resp.data);
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    getAllDataDeliveryType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/delivery-type", {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.deliveryType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataZone(ids) {
      //console.log(ids)
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/zone/territory", {
          params: {
            territory_ids: ids,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.zone.options = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataSales(zoneId) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-sales/zone/" + zoneId)
        .then((resp) => {
          if (resp.status == "success") {
            this.sales.options = resp.data;
            //console.log(this.sales.options);
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCode() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/setting/master-number-code/CUST")
        .then((resp) => {
          if (resp.status == "success") {
            this.data.code = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataTaxType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/tax-type", {
          params: {
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.taxType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setCustomerCategorySelected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-category/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerCategory.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setPricingGroupSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/pricing-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.pricingGroup.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerGroup1Selected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup1.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerGroup2Selected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup2.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerGroup3Selected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup3.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setPaymentTermSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/payment-term/" + id).then((resp) => {
        if (resp.status == "success") {
          this.paymentTerm.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setZoneSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/zone/" + id).then((resp) => {
        if (resp.status == "success") {
          this.zone.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setDeliveryTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/delivery-type/" + id).then((resp) => {
        if (resp.status == "success") {
          this.deliveryType.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setSalesPersonalSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/setting/personal/" + id).then((resp) => {
        if (resp.status == "success") {
          this.sales.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setTaxTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/tax-type/" + id).then((resp) => {
        if (resp.status == "success") {
          this.taxType.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    pushToCustomerCategory() {
      this.$emit("tab", "Distribution Channel");
    },
    pushToPricingGroup() {
      this.$emit("tab", "Pricing Group");
    },
    pushToCustomerGroup() {
      this.$emit("tab", "Customer Group");
    },
    pushToPaymentTerm() {
      // this.$emit("tab", "Payment Term");
      this.$router.push("/master/payment-term");
    },
    setPaymentMethodId(val) {
      this.data.paymentMethodId = val;
    },
    setParentId(val) {
      this.data.parentId = val;
    },
    setStatus(val) {
      this.data.status = val;
    },
    setPriorityType(val) {
      this.data.priorityType = val;
    },
    setDataAddress(val) {
      this.data.address = val;
    },
  },
  mounted() {
    this.getAllDataCustomerCategory();
    this.getAllDataPricingGroup();
    this.getAllDataCustomerGroup1();
    this.getAllDataCustomerGroup2();
    this.getAllDataCustomerGroup3();
    this.getAllDataPaymentTerm();
    this.getAllDataDeliveryType();
    this.getCode();
    this.getDataTaxType();
    //this.getAllDataTerritory();
    if (this.customerId) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    customerId(v) {
      this.getAllDataCustomerCategory();
      this.getAllDataCustomerGroup1();
      this.getAllDataCustomerGroup2();
      this.getAllDataCustomerGroup3();
      this.getAllDataPaymentTerm();
      this.getAllDataDeliveryType();
      this.getDataTaxType();
      //this.getAllDataTerritory();
      Object.assign(this.$data, this.initialState());
      if (v) {
        this.getData();
      }
    },
    // "territory.selected": function (val) {
    //   let ids = [];
    //   if (typeof val === "object") {
    //     if (Array.isArray(val)) {
    //       val.forEach((item) => {
    //         ids.push(item.id ? item.id : item.ID );
    //       });
    //     } else {
    //       if (val) {
    //         ids.push(val.id ? val.id : val.ID);
    //       }
    //       this.zone.selected = null;
    //     }
    //   }
    //   this.getDataZone(ids);
    // },
    // "zone.selected": function (val) {
    //   this.sales.selected = null;
    //   if (val) {
    //     this.errors.clear();
    //     this.data.customerZone.zoneId = val.id ? val.id : val.ID;
    //     this.getDataSales(val.id ? val.id : val.ID);
    //   } else {
    //     this.data.customerZone.zoneId = null;
    //     this.sales.options = [];
    //     this.sales.selected = null;
    //   }
    // },
    // "sales.selected": function (val) {
    //   if (val) {
    //     this.errors.clear();
    //   } else {
    //     this.sales.selected = null;
    //   }
    // },
    "customerCategory.selected": function (val) {
      if (val) {
        this.errors.clear();
        this.data.customerCategoryId = val.id ? val.id : val.ID;
      } else {
        this.data.customerCategoryId = null;
      }
    },
    "pricingGroup.selected": function (val) {
      if (val) {
        this.errors.clear();
        this.data.pricingGroupId = val.id ? val.id : val.ID;
      } else {
        this.data.pricingGroupId = null;
      }
    },
    "customerGroup1.selected": function (val) {
      if (val) {
        //console.log(val)
        this.errors.clear();
        this.data.customerGroupId1 = val.id ? val.id : val.ID;
        if (this.paymentType.selected == "Credit"  && this.data.customerGroupId1 != null && this.data.customerGroupId2 != null && this.data.customerGroupId3 != null) {
          this.$vs.loading();
          this.$http.get("/api/v1/master/customer-group-limit/payment-term", {
            params: {
              customer_group_id1: this.data.customerGroupId1,
              customer_group_id2: this.data.customerGroupId2,
              customer_group_id3: this.data.customerGroupId3,
            },
          }).then((resp) => {
            if (resp.status == "success") {
              this.data.customerLimit.amount = resp.data[0].records.credit_limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
        }
      } else {
        this.data.customerGroupId1 = null;
      }
    },
    "customerGroup2.selected": function (val) {
      if (val) {
        //console.log(val)
        this.errors.clear();
        this.data.customerGroupId2 = val.id ? val.id : val.ID ;
        if (this.paymentType.selected == "Credit" && this.data.customerGroupId1 != null && this.data.customerGroupId2 != null && this.data.customerGroupId3 != null) {
          this.$vs.loading();
          this.$http.get("/api/v1/master/customer-group-limit/payment-term", {
            params: {
              customer_group_id1: this.data.customerGroupId1,
              customer_group_id2: this.data.customerGroupId2,
              customer_group_id3: this.data.customerGroupId3,
            },
          }).then((resp) => {
            if (resp.status == "success") {
              this.data.customerLimit.amount = resp.data[0].records.credit_limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
        }
      } else {
        this.data.customerGroupId2 = null;
      }
    },
    "customerGroup3.selected": function (val) {
      if (val) {
        //console.log(val)
        this.errors.clear();
        this.data.customerGroupId3 = val.id ? val.id : val.ID ;
        if (this.paymentType.selected == "Credit" && this.data.customerGroupId1 != null && this.data.customerGroupId2 != null && this.data.customerGroupId3 != null) {
          this.$vs.loading();
          this.$http.get("/api/v1/master/customer-group-limit/payment-term", {
            params: {
              customer_group_id1: this.data.customerGroupId1,
              customer_group_id2: this.data.customerGroupId2,
              customer_group_id3: this.data.customerGroupId3,
            },
          }).then((resp) => {
            if (resp.status == "success") {
              this.data.customerLimit.amount = resp.data[0].records.credit_limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
        }
      } else {
        this.data.customerGroupId3 = null;
      }
    },
    "paymentType.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        console.log("paymentType.selected", val)
        //if (val == "Credit" && this.customerId == null && this.data.customerGroupId1 != null && this.data.customerGroupId2 != null && this.data.customerGroupId3 != null) {
        if (val == "Credit") {
          this.$vs.loading();
          this.$http.get("/api/v1/master/payment-term/non-cod", {
            params: {
              order: "name",
              sort: "asc",
            },
          }).then((resp) => {
            if (resp.status == "success") {
              this.paymentTerm.options = resp.data.records;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
        } else if (val == "Cash") {
          this.$vs.loading();
          this.$http.get("/api/v1/master/payment-term/cod", {
            params: {
              payment_term_id: 7, //id untuk cod
              order: "name",
              sort: "asc",
            },
          }).then((resp) => {
            if (resp.status == "success") {
              this.paymentTerm.options = resp.data.records;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
        }

        this.paymentType.selected = val;
      } else {
        this.paymentType.selected = "Cash";
      }
    },
    "paymentTerm.selected": function (val) {
     // console.log(val, "paymentTerm.selected")
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.paymentTermId = val.id ? val.id : val.ID;
        this.data.paymentMethodId = val.payment_method_id;
      } else {
        this.data.paymentTermId = null;
      }
    },
    // "data.paymentMethodId": function (val) {
    //   if (this.paymentTerm.selected &&  val !== undefined) {
    //     //console.log(val,"data.paymentMethodId")
    //     if (this.paymentTerm.selected.payment_method_id != val) {
    //       this.paymentTerm.selected = null;
    //     }
    //   }
    //   //this.getAllDataPaymentTerm();
    // },
    "deliveryType.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.deliveryTypeId = val.id ? val.id : val.ID;
      } else {
        this.data.deliveryTypeId = null;
      }
    },
    "priority.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.priorityType = val;
      } else {
        this.data.priorityType = null;
      }
    },
    "npwpType.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.npwpType.selected = val;
      } else {
        this.npwpType.selected = "Non PKP";
      }
    },
    "data.password": function (val) {
      if (val.length >= 8) {
        this.errors.clear();
      }
    },
    "data.customerLimit.amount": function (val) {
      if (val != null) {
        val = val.toString().replace(/\./g, "");
        if (isNaN(parseInt(val))) {
          this.errors.add({
            field: "CreditLimit",
            msg: "The CreditLimit field is number required",
          });
        } else {
          this.errors.clear();
        }
        this.data.customerLimit.amount = val
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        this.data.customerLimit.amount = 0;
      }
    },
    "data.isPkp": function (val) {
      console.log("isPkp", val);
    },
    "taxType.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.taxTypeId = val.id ? val.id : val.ID;
      } else {
        this.data.taxTypeId = null;
      }
    },
  },
};
</script>
<style >
.ps {
  height: 800px;
}
</style>
